import React from "react"
import { graphql } from "gatsby"

import { Form, Link } from "@elements"
import { SEO, Layout } from "@global"
import { useGravityForms } from "@hooks"
import Facebook from "@svg/facebook.svg"
import Instagram from "@svg/instagram.svg"
import Twitter from "@svg/twitter.svg"
import Youtube from "@svg/youtube.svg"
import LinkedIn from "@svg/linkedin.svg"

export default ({ data }) => {
  const formId = 2
  const form = useGravityForms(formId)

  const { page } = data.wpgraphql
  const pageNiceTitle = page.pageContactHeader.contactTitle
  const pageIntroContent = page.pageContactHeader.contactIntro
  const pageOutroContent = page.pageContactHeader.contactOutro

  const contactInfo =
    data.wpgraphql.optionsContactSocial.options_global.contactInformation

  return (
    <Layout>
      <SEO title={page.title} />
      <div className="container px-4">
        <div className="my-20">
          <h1 className="mb-8 text-3xl font-light leading-tight tracking-tight sm:text-3xl lg:text-5xl font-display">
            {pageNiceTitle}
          </h1>
          <div
            className="mb-8 contact-text-container"
            dangerouslySetInnerHTML={{ __html: pageIntroContent }}
          ></div>
          <div className="flex flex-wrap">
            <div className="w-full p-12 pl-8 bg-white lg:w-3/5">
              <h1 className="mb-8 text-2xl font-light leading-tight tracking-tight sm:text-3xl lg:text-5xl font-display">
                Contact Us.
              </h1>
              <Form
                form={form}
                submitButton={({ isSubmitting }) => (
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className={`mt-4 btn primary w-full sm:w-auto text-center ${
                        isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      disabled={isSubmitting}
                    >
                      Send Message
                    </button>
                  </div>
                )}
              />
            </div>

            <div className="w-full p-12 lg:w-2/5">
              <h3 className="mb-4 text-lg font-medium font-display">Phone</h3>

              <Link
                to={`tel:${contactInfo.phoneNumber.replace(/\(|\)|-|\s/g, "")}`}
                className="block mb-4 text-teal-500"
              >
                {contactInfo.phoneNumber}
              </Link>
              <Link
                to={`tel:${contactInfo.phoneNumber2.replace(
                  /\(|\)|-|\s/g,
                  ""
                )}`}
                className="block mb-8 text-teal-500"
              >
                {contactInfo.phoneNumber2}
              </Link>

              <h3 className="mb-4 text-lg font-medium font-display">Email</h3>
              <Link
                to={`mailto:${contactInfo.emailAddress}`}
                className="block mb-4 text-teal-500"
              >
                {contactInfo.emailAddress}
              </Link>

              <h3 className="mb-4 text-lg font-medium font-display">Social</h3>

              <div className="flex flex-wrap items-center">
                {/* Todo missing linkedin icon*/}
                {contactInfo.linkedinUrl && (
                  <Link className="mr-4" to={contactInfo.linkedinUrl}>
                    <img
                      className="w-8 h-8 mr-4"
                      alt="linkedin icon"
                      src={LinkedIn}
                    />
                  </Link>
                )}
                {contactInfo.facebookUrl && (
                  <Link className="mr-4" to={contactInfo.facebookUrl}>
                    <img
                      className="w-8 h-8 mr-4"
                      alt="facebook icon"
                      src={Facebook}
                    />
                  </Link>
                )}
                {contactInfo.instagramUrl && (
                  <Link className="mr-4" to={contactInfo.instagramUrl}>
                    <img
                      className="w-8 h-8 mr-4"
                      alt="instagram icon"
                      src={Instagram}
                    />
                  </Link>
                )}
                {/* Todo missing snapchat icon*/}
                {contactInfo.snapchatUrl && (
                  <Link className="mr-4" to={contactInfo.snapchatUrl}>
                    <img
                      className="w-8 h-8 mr-4"
                      alt="snapchat icon"
                      src={Facebook}
                    />
                  </Link>
                )}
                {contactInfo.twitterUrl && (
                  <Link className="mr-4" to={contactInfo.twitterUrl}>
                    <img
                      className="w-8 h-8 mr-4"
                      alt="twitter icon"
                      src={Twitter}
                    />
                  </Link>
                )}
                {contactInfo.youtubeUrl && (
                  <Link className="mr-4" to={contactInfo.youtubeUrl}>
                    <img
                      className="w-8 h-8 mr-4"
                      alt="youtube icon"
                      src={Youtube}
                    />
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div
            className="mb-8 contact-text-container"
            style={{ marginTop: "2rem" }}
            dangerouslySetInnerHTML={{ __html: pageOutroContent }}
          ></div>
        </div>
      </div>
    </Layout>
  )
}

export const ContactUsPageQuery = graphql`
  query ContactUsPageQuery($id: ID!) {
    wpgraphql {
      ...OptionsContactSocial
      page(id: $id) {
        title
        pageContactHeader {
          contactIntro
          contactOutro
          contactTitle
        }
      }
    }
  }
`
